import EventCenter from "./EventCenter";

class socket {
	// 要连接的URL
	url;
	// 一个协议字符串或一个协议字符串数组。
	// 这些字符串用来指定子协议，这样一个服务器就可以实现多个WebSocket子协议
	options = {
		protocols: [],
		path: ''
	};
	// WebSocket 实例
	socket;
	// 是否在重连中
	isReconnectionLoading = false;
	// 延时重连的 id
	timeId = null;
	// 是否是用户手动关闭连接
	isCustomClose = false;
	// 错误消息队列
	errorStack = [];
	// 消息管理中心
	eventCenter = new EventCenter();
	
	heartBeatTimer;
	
	constructor(url, options) {
		this.url = url;
		Object.assign(this.options, options);
		this.init();
	}
	
	init() {
		if ('WebSocket' in window) {
			// 实例化
			this.socket = new WebSocket(this.url.concat(this.options.path), this.options.protocols);
			// 监听事件
			this.onopen();
			this.onerror();
			this.onclose();
			this.onmessage();
		} else {
			console.log('你的浏览器不支持 WebSocket');
		}
	}
	
	// 监听成功
	onopen() {
		this.socket.onopen = (data) => {
			this.heartBeatTimer = setInterval(() => this.send({
				cmd: 'home.heartbeat',
				data: {
					message: "ping"
				}
			}), 20000);
			
			// console.log(this.socket, 'onopen');
			
			this.eventCenter.emit('open', data);
			// 发送成功连接之前所发送失败的消息
			this.errorStack.forEach(message => {
				this.send(message);
			});
			this.errorStack = [];
			this.isReconnectionLoading = false;
		};
	}
	
	// 监听错误
	onerror() {
		if (this.heartBeatTimer) {
			clearInterval(this.heartBeatTimer);
			this.heartBeatTimer = null;
		}
		this.socket.onerror = (err) => {
			console.log(err, 'onerror');
			this.reconnection();
			this.isReconnectionLoading = false;
		};
	}
	
	// 监听关闭
	onclose() {
		if (this.heartBeatTimer) {
			clearInterval(this.heartBeatTimer);
			this.heartBeatTimer = null;
		}
		this.socket.onclose = (data) => {
			console.log('onclose');
			this.eventCenter.emit('close', data);
			
			// 用户手动关闭的不重连
			if (this.isCustomClose) return;
			
			this.reconnection();
			this.isReconnectionLoading = false;
		};
	}
	
	// 接收 WebSocket 消息
	onmessage() {
		this.socket.onmessage = async (event) => {
			try {
				const data = JSON.parse(event.data);
				await this.eventCenter.emit(data.cmd, data.data);
			} catch (error) {
				console.log(error, 'error');
			}
		};
	}
	
	// 重连
	reconnection() {
		// 防止重复
		if (this.isReconnectionLoading) return;
		
		this.isReconnectionLoading = true;
		clearTimeout(this.timeId);
		this.timeId = setTimeout(() => {
			this.init();
		}, 3000);
	}
	
	// 发送消息
	send(message) {
		console.log(message);
		// 连接失败时的处理
		if (this.socket.readyState !== 1) {
			this.errorStack.push(message);
			return;
		}
		
		this.socket.send(message instanceof Object ? JSON.stringify(message) : message);
	}
	
	// 手动关闭
	close() {
		if (this.heartBeatTimer) {
			clearInterval(this.heartBeatTimer);
			this.heartBeatTimer = null;
		}
		this.isCustomClose = true;
		this.socket.close();
	}
	
	// 手动开启
	start() {
		this.isCustomClose = false;
		this.reconnection();
	}
	
	// 监听事件
	on(eventName, cb) {
		console.log(eventName);
		this.eventCenter.on(eventName, cb);
	}
	
	// 监听事件 只执行一次
	once(eventName, cb) {
		this.eventCenter.once(eventName, cb);
	}
	
	// 取消监听
	off(eventName, cb) {
		this.eventCenter.off(eventName, cb);
	}
	
	// 向服务端发出事件数据
	emit(eventName, data) {
		this.send({ cmd: eventName, data });
	}
	
	// 销毁
	destroy() {
		if (this.heartBeatTimer) {
			clearInterval(this.heartBeatTimer);
			this.heartBeatTimer = null;
		}
		this.close();
		this.socket = null;
		this.errorStack = null;
	}
}

/**
 *
 * @param url
 * @param options
 * @returns {socket.socket}
 */
function useWebsocket(url, options = {}) {
	return new socket(url, options);
}

export default useWebsocket;
