import Layout from "@/layout";

export default {
	path: '/c2c',
	component: Layout,
	children: [{
		path: '',
		component: () => import('@/views/c2c'),
		name: 'C2C',
		meta: { title: 'c2c' }
	}, {
		path: 'detail/:d/:id/:type',
		component: () => import('@/views/c2c/detail'),
		name: 'C2CDetail',
		meta: { title: 'c2c_detail' }
	}, {
		path: 'create-order/:id',
		component: () => import('@/views/c2c/create-order'),
		name: 'C2CCreateOrder',
		meta: { title: 'c2c_create_order' }
	}]
};
