import ls from "./storage";

export const TokenKey = 'AuthenticationToken';
export const TokenExpiredKey = 'tokenExpired';
const InfoKey = 'userInfo';

export function getToken() {
	return ls.get(TokenKey);
}

export function setToken(value) {
	return ls.set(TokenKey, value);
}

export function removeToken() {
	return ls.remove(TokenKey);
}

export function isTokenExpired() {
	return ls.get(TokenExpiredKey);
}

export function tokenExpired() {
	return ls.set(TokenExpiredKey, true);
}

export function removeTokenExpired() {
	return ls.remove(TokenExpiredKey);
}

export function getUserInfo() {
	return ls.get(InfoKey);
}

export function setUserInfo(value) {
	return ls.set(InfoKey, value);
}

export function removeUserInfo() {
	return ls.remove(InfoKey);
}
