import Layout from "@/layout";
import store from "@/store";

export default {
	path: '/contract',
	component: Layout,
	children: [{
		path: ':symbol?/:index?',
		component: () => import('@/views/contract'),
		name: 'Contract',
		meta: { title: 'contract' },
		beforeEnter: async (to, from, next) => {
			if (!to.params.symbol) {
				await store.dispatch('common/getContractSymbols');
				next({ ...to, params: { ...to.params, symbol: encodeURIComponent(store.getters.default_contract_symbol) } });
			} else {
				next();
			}
		}
	}, {
		path: 'markets/:symbol/:interval?',
		component: () => import('@/views/contract/markets'),
		name: 'ContractMarkets',
		meta: { title: 'contract_markets' }
	}, {
		path: 'share',
		component: () => import('@/views/contract/share'),
		name: 'Share',
		meta: { title: 'share' }
	}]
};
