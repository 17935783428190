import Layout from "@/layout";
import store from "@/store";

export default {
	path: '/currency-exchange',
	component: Layout,
	children: [{
		path: ':symbol?/:index?/:direction(buy|sell)?',
		component: () => import('@/views/currency_exchange'),
		name: 'CurrencyExchange',
		meta: { title: 'currency_exchange' },
		beforeEnter: async (to, from, next) => {
			if (!to.params.symbol) {
				await store.dispatch('common/getTradeSymbols');
				next({ ...to, params: { ...to.params, symbol: encodeURIComponent(store.getters.default_trade_symbols) } });
			} else {
				next();
			}
		}
	}, {
		path: 'markets/:symbol/:interval?',
		component: () => import('@/views/currency_exchange/markets'),
		name: 'CurrencyExchangeMarkets',
		meta: { title: 'currency_exchange_markets' }
	}, {
		path: 'orders',
		component: () => import('@/views/currency_exchange/orders'),
		name: 'CurrencyExchangeOrders',
		meta: { title: 'currency_exchange_orders' }
	}]
};
