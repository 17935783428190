import Layout from "@/layout";

export default {
	path: '/',
	component: Layout,
	children: [{
		path: '',
		component: () => import('@/views/home'),
		name: 'Home',
		meta: { title: 'home' }
	}]
};
