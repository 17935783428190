const getters = {
	token: state => state.auth.token,
	hasLogin: state => JSON.stringify(state.auth.userInfo) !== "{}" && state.auth.token,
	userInfo: state => state.auth.userInfo,
	walletHideMoney: state => state.auth.walletHideMoney,
	countries: state => state.common.countries,
	logoMap: state => state.common.logoMap,
	
	ws: state => state.common.ws,
	trade_symbols: state => state.common.trade_symbols,
	default_trade_symbols: state => state.common.default_trade_symbols,
	contract_symbols: state => state.common.contract_symbols,
	default_contract_symbol: state => state.common.default_contract_symbol,
	default_option_scene: state => state.common.default_option_scene,
	
	// ctoc 法币符号
	currency: () => process.env.VUE_APP_CURRENCY,
	// ctoc 法币ISO代码
	legal_tender: () => process.env.VUE_APP_LEGAL_TENDER,
};

export default getters;
