<template>
	<router-view v-slot="{ Component }">
		<keep-alive>
			<component :is="Component" :key="$route.path" />
		</keep-alive>
	</router-view>
</template>

<script setup name="Layout">
</script>

<style scoped>

</style>
