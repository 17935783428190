import { getToken, setToken, removeToken, /*getUserInfo,*/ setUserInfo, removeUserInfo } from "@/utils/auth";
import { login, getInfo, logout } from "@/api/auth";
import { ls } from "@/utils";

const state = {
	token: getToken(),
	hasInfo: false,
	userInfo: {},
	walletHideMoney: ls.get('wallet_hide_money') || false
};

const mutations = {
	SET_TOKEN(state, token) {
		state.token = token;
	},
	SET_HAS_INFO(state) {
		state.hasInfo = true;
	},
	SET_USER_INFO(state, userInfo) {
		state.userInfo = userInfo;
	},
	SET_WALLET_HIDE_MONEY(state, walletHideMoney) {
		ls.set('wallet_hide_money', walletHideMoney);
		state.walletHideMoney = walletHideMoney;
	}
};

const actions = {
	// user login
	login({ commit }, userInfo) {
		return new Promise((resolve, reject) => {
			login(userInfo).then(response => {
				const { data: { token, user } } = response;
				commit('SET_TOKEN', token);
				setToken(token);
				commit('SET_USER_INFO', user);
				setUserInfo(user);
				resolve();
			}).catch(error_page => {
				reject(error_page);
			});
		});
	},

	// get user info
	getInfo({ commit }) {
		return new Promise((resolve, reject) => {
			getInfo().then(response => {
				const { data } = response;
				if (!data) {
					reject('Verification failed, please Login again.');
				}

				commit('SET_USER_INFO', data);
				commit('SET_HAS_INFO');
				resolve(data);
			}).catch(error_page => {
				reject(error_page);
			});
		});
	},

	// user logout
	logout({ commit, state }) {
		return new Promise((resolve, reject) => {
			logout(state.token).then(() => {
				commit('SET_TOKEN', '');
				commit('SET_USER_INFO', {});
				state.hasInfo = false;
				removeToken();
				removeUserInfo();

				resolve();
			}).catch(error_page => {
				reject(error_page);
			});
		});
	},

	// refresh token
	refreshToken({ commit }, token) {
		return new Promise(resolve => {
			commit('SET_TOKEN', token);
			setToken(token);
			resolve();
		});
	},

	// remove token
	resetToken({ commit }) {
		return new Promise(resolve => {
			commit('SET_TOKEN', '');
			removeToken();
			removeUserInfo();
			resolve();
		});
	},
	
	hideMoney({ commit, state }) {
		return new Promise(resolve => {
			commit('SET_WALLET_HIDE_MONEY', !state.walletHideMoney);
			resolve();
		});
	},

	// // dynamically modify permissions
	// async changeRoles({ commit, dispatch }, role) {
	//     const token = role + '-token';
	//
	//     commit('SET_TOKEN', token);
	//     setToken(token);
	//
	//     const { roles } = await dispatch('getInfo');
	//
	//     resetRouter();
	//
	//     // generate accessible routes map based on roles
	//     const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true });
	//     // dynamically add accessible routes
	//     router.addRoute(accessRoutes);
	//
	//     // reset visited views and cached views
	//     dispatch('tagsView/delAllViews', null, { root: true });
	// }
};

export default {
	namespaced: true,
	state,
	mutations,
	actions
};
