import Layout from "@/layout";
import store from "@/store";

export default {
	path: '/options',
	component: Layout,
	children: [{
		path: '',
		component: () => import('@/views/options'),
		name: 'Options',
		meta: { title: 'options' }
	}, {
		path: 'trade/:trade_pair_timer?/:interval?',
		component: () => import('@/views/options/trade'),
		name: 'OptionsTrade',
		meta: { title: 'options_trade' },
		beforeEnter: async (to, from, next) => {
			if (!to.params.trade_pair_timer) {
				await store.dispatch('common/getOptionScenes');
				to.params.trade_pair_timer = encodeURIComponent(store.getters.default_option_scene);
				next(to);
			}
			if (!to.params.interval) {
				to.params.interval = process.env.VUE_APP_OPTIONS_DEFAULT_INTERVAL || 1;
				next(to);
			}
			next();
		}
	}, {
		path: 'order',
		component: () => import('@/views/options/order'),
		name: 'OptionsOrder',
		meta: { title: 'options_order' }
	}, {
		path: 'order_detail/:order_id',
		component: () => import('@/views/options/order_detail'),
		name: 'OptionsOrderDetail',
		meta: { title: 'options_order_detail' }
	}]
};
