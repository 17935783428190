import { getCountries, getLogo } from "@/api/common";
import ls from "@/utils/storage";
import { isDuringDate, useWebsocket } from "@/utils";
import { getMarketList as getTradeSymbols } from "@/api/exchange";
import { getMarketList as getContractSymbols } from "@/api/contract";
import { sceneListByTimes } from "@/api/options";

const state = {
	logoMap: {},
	countries: [],
	ws: false,
	trade_symbols: [],
	default_trade_symbols: '',
	contract_symbols: [],
	default_contract_symbol: '',
	default_option_scene: ''
};

const mutations = {
	SET_LOGO(state, logoMap) {
		// document.querySelector('link[rel="icon"]').setAttribute('href', logoMap.titles_logo);
		// 添加页面ico
		const iconDom = document.createElement("link");
		iconDom.setAttribute('rel', 'icon');
		iconDom.setAttribute('href', logoMap.titles_logo);
		document.head.appendChild(iconDom);
		// 给页面title设置name
		document.title = logoMap.name;
		
		state.logoMap = logoMap;
	},
	SET_COUNTRIES(state, countries) {
		state.countries = countries;
	},
	SET_WS_CONNECTED(state, isConnected) {
		state.ws = isConnected;
	},
	SET_TRADE_SYMBOLS(state, data) {
		state.trade_symbols = data;
		state.default_trade_symbols = data[0].marketInfoList[0].pair_name;
	},
	SET_CONTRACT_SYMBOLS(state, data) {
		state.contract_symbols = data;
		state.default_contract_symbol = data[0].marketInfoList[0].pair_name;
	},
	SET_OPTION_SCENES(state, data) {
		state.option_scenes = data;
		state.default_option_scene = data[0].scenePairList[0].pair_time_name;
	}
};

const actions = {
	getLogo({ commit }) {
		return new Promise(resolve => {
			const logoMap = ls.get('logoMap');
			if(logoMap) {
				commit('SET_LOGO', logoMap);
				resolve(logoMap);
			} else {
				getLogo().then(response => {
					const { data } = response;
					commit('SET_LOGO', data);
					ls.set('logoMap', data, 100);
					resolve(data);
				});
			}
		});
	},
	getCountries({ commit }) {
		return new Promise(resolve => {
			const countries = ls.get('countries');
			if(countries) {
				commit('SET_COUNTRIES', countries);
				resolve(countries);
			} else {
				getCountries().then(response => {
					const { data } = response;
					commit('SET_COUNTRIES', data);
					ls.set('countries', data, 100);
					resolve(data);
				});
			}
		});
	},
	getTradeSymbols({ commit }) {
		return new Promise(resolve => {
			const symbols = ls.get('trade_symbols');
			
			// 判断当前时间是否为00:00 - 00:30
			if(!symbols || isDuringDate(new Date(new Date().setHours(0, 0, 0, 0)), new Date(new Date().setHours(0, 30, 0, 0)))) {
				getTradeSymbols().then(response => {
					const { data } = response;
					commit('SET_TRADE_SYMBOLS', data);
					ls.set('trade_symbols', data, 30);
					resolve(data);
				});
			} else {
				commit('SET_TRADE_SYMBOLS', symbols);
				resolve(symbols);
			}
		});
	},
	getContractSymbols({ commit }) {
		return new Promise(resolve => {
			const symbols = ls.get('contract_symbols');
			// 判断当前时间是否为00:00 - 00:30
			if(!symbols || isDuringDate(new Date(new Date().setHours(0, 0, 0, 0)), new Date(new Date().setHours(0, 30, 0, 0)))) {
				getContractSymbols().then(response => {
					const { data } = response;
					commit('SET_CONTRACT_SYMBOLS', data);
					ls.set('contract_symbols', data, 30);
					resolve(data);
				});
			} else {
				commit('SET_CONTRACT_SYMBOLS', symbols);
				resolve(symbols);
			}
		});
	},
	getOptionScenes({ commit }) {
		return new Promise(resolve => {
			const scenes = ls.get('option_scenes');
			// 判断当前时间是否为00:00 - 00:30
			if(!scenes || isDuringDate(new Date(new Date().setHours(0, 0, 0, 0)), new Date(new Date().setHours(0, 30, 0, 0)))) {
				sceneListByTimes().then(response => {
					const { data } = response;
					commit('SET_OPTION_SCENES', data);
					ls.set('option_scenes', data, 30);
					resolve(data);
				});
			} else {
				commit('SET_OPTION_SCENES', scenes);
				resolve(scenes);
			}
		});
	},
	wsConnection({ commit }) {
		commit('SET_WS_CONNECTED', useWebsocket(process.env.NODE_ENV === 'development' ? process.env.VUE_APP_WS_REQUEST_BASE_URL : process.env.VUE_APP_WS_BASE_URL));
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions
};
