import request from '@/utils/request';

export function login(data) {
	return request({
		url: 'user/login',
		method: 'post',
		data
	});
}

export function logout() {
	return request({
		url: 'user/logout',
		method: 'post'
	});
}

export function getInfo() {
	return request({
		url: 'user/getUserInfo',
		method: 'get'
	});
}

export function forgetPasswordAttempt(data) {
	return request({
		url: 'user/forgetPasswordAttempt',
		method: 'post',
		data
	});
}

export function forgetPassword(data) {
	return request({
		url: 'user/forgetPassword',
		method: 'post',
		data
	});
}

export function getClause() {
	return request({
		url: 'login/clause',
		method: 'get'
	});
}

export function register(data) {
	return request({
		url: 'user/register',
		method: 'post',
		data
	});
}

export function sendEmailCode(data) {
	return request({
		url: 'register/sendEmailCode',
		method: 'post',
		data
	});
}

export function sendSmsCode(data) {
	return request({
		url: 'register/sendSmsCode',
		method: 'post',
		data
	});
}

export function fundAccount(data) {
	return request({
		url: 'user/fundAccount',
		method: 'post',
		data
	});
}

export function personalAssets(data) {
	return request({
		url: 'user/personalAssets',
		method: 'post',
		data
	});
}

export function getAuthInfo() {
	return request({
		url: 'user/getAuthInfo',
		method: 'get'
	});
}

export function authPrimary(data) {
	return request({
		url: 'user/primaryAuth',
		method: 'post',
		data
	});
}

export function authSenior(data) {
	return request({
		url: 'user/topAuth',
		method: 'post',
		data
	});
}

export function myNotifiables(params) {
	return request({
		url: 'user/myNotifiables',
		method: 'get',
		params
	});
}

export function readNotifiable(params) {
	return request({
		url: 'user/readNotifiable',
		method: 'get',
		params
	});
}

export function getGoogleToken(params) {
	return request({
		url: 'user/getGoogleToken',
		method: 'get',
		params
	});
}

export function getCode(data) {
	return request({
		url: 'user/getCode',
		method: 'post',
		data
	});
}

export function bindGoogleToken(data) {
	return request({
		url: 'user/bindGoogleToken',
		method: 'post',
		data
	});
}

export function unbindGoogleToken(data) {
	return request({
		url: 'user/unbindGoogleToken',
		method: 'post',
		data
	});
}

export function updatePassword(data) {
	return request({
		url: 'user/updatePassword',
		method: 'post',
		data
	});
}

export function setOrResetPaypwd(data) {
	return request({
		url: 'user/setOrResetPaypwd',
		method: 'post',
		data
	});
}

export function sendBindSmsCode(data) {
	return request({
		url: 'user/sendBindSmsCode',
		method: 'post',
		data
	});
}

export function unbindPhone(data) {
	return request({
		url: 'user/unbindPhone',
		method: 'post',
		data
	});
}

export function unbindEmail(data) {
	return request({
		url: 'user/unbindEmail',
		method: 'post',
		data
	});
}

export function bindPhone(data) {
	return request({
		url: 'user/bindPhone',
		method: 'post',
		data
	});
}

export function bindEmail(data) {
	return request({
		url: 'user/bindEmail',
		method: 'post',
		data
	});
}

export function generalizeInfo() {
	return request({
		url: 'generalize/info',
		method: 'get'
	});
}

export function generalizeList(params) {
	return request({
		url: 'generalize/list',
		method: 'get',
		params
	});
}

export function sendSmsCodeForgetPassword(data) {
	return request({
		url: 'user/sendSmsCodeForgetPassword',
		method: 'post',
		data
	});
}

export function sendEmailCodeForgetPassword(data) {
	return request({
		url: 'user/sendEmailCodeForgetPassword',
		method: 'post',
		data
	});
}

export function sendBindEmailCode(data) {
	return request({
		url: 'user/sendBindEmailCode',
		method: 'post',
		data
	});
}
