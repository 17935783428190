<template>
	<component :is="type" v-bind="linkProps(to)" class="c-link">
		<slot/>
	</component>
</template>

<script setup name="Link">
	import { isExternal } from '@/utils/validate';
	import { defineProps } from "vue";

	const props = defineProps({
		to: {
			type: String,
			required: true
		}
	});

	const link = isExternal(props.to);
	const type = link ? 'a' : props.to ? 'router-link' : 'span';

	function linkProps(to) {
		if (link) {
			return {
				href: to,
				target: '_blank',
				rel: 'noopener'
			};
		}
		return { to };
	}
</script>

<style lang="scss" scoped>
.c-link {
	color: inherit;
	text-decoration: none;
	cursor: pointer;
	
	
	&.no-decoration {
		text-decoration: none !important;
	}
}
</style>
