import request from "@/utils/request";

export function sceneListByTimes() {
	return request({
		url: 'option2/sceneListByTimes',
		method: 'get'
	});
}

export function sceneListByPairs() {
	return request({
		url: 'option2/sceneListByPairs',
		method: 'get'
	});
}

export function getOddsList(params) {
	return request({
		url: 'option/getOddsList',
		method: 'get',
		params
	});
}

export function getOddsListByTimes(params) {
	return request({
		url: 'option2/getOddsListByTimes',
		method: 'get',
		params
	});
}

export function getBetCoinList(params) {
	return request({
		url: 'option/getBetCoinList',
		method: 'get',
		params
	});
}

export function getUserCoinBalance(params) {
	return request({
		url: 'option/getUserCoinBalance',
		method: 'get',
		params
	});
}

export function betOrder(data) {
	return request({
		url: 'option2/betOrder',
		method: 'post',
		data
	});
}

export function getOptionHistoryOrders(params) {
	return request({
		url: 'option2/getOptionHistoryOrders',
		method: 'get',
		params
	});
}

export function getOptionOrderDetail(params) {
	return request({
		url: 'option/getOptionOrderDetail',
		method: 'get',
		params
	});
}
