import request from '@/utils/request';

export function getLogo() {
	return request({
		url: 'index/logo',
		method: 'get',
	});
}

export function getIndexData(params) {
	return request({
		url: 'indexList',
		method: 'get',
		params
	});
}

export function getCountries() {
	return request({
		url: 'getCountryList',
		method: 'get'
	});
}

export function uploadFile(data) {
	return request({
		url: 'uploadImage',
		method: 'post',
		data
	});
}

export function getCollect() {
	return request({
		url: 'getCollect',
		method: 'get'
	});
}

export function collect(data) {
	return request({
		url: 'option',
		method: 'post',
		data
	});
}
