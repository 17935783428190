import Layout from "@/layout";
import { useStore } from "vuex";

export default {
	path: '/profile',
	component: Layout,
	children: [{
		path: '',
		component: () => import('@/views/profile'),
		name: 'Profile',
		meta: { title: 'profile', keepAlive: true }
	}, {
		path: 'safe',
		children: [{
			path: '',
			component: () => import('@/views/profile/safe'),
			name: 'Safe',
			meta: { title: 'safe' }
		}, {
			path: 'google/:step?',
			component: () => import('@/views/profile/safe/google'),
			name: 'Google',
			meta: { title: 'google' },
			props: true, // 启用 props 将参数传递给组件
			beforeEnter: (to, from, next) => {
				if (!to.params.step) {
					const store = useStore();
					if(store.getters.userInfo?.google_token) {
						next({ ...to, params: { ...to.params, step: 'unBind' } });
					} else {
						next({ ...to, params: { ...to.params, step: 1 } });
					}
				} else {
					next();
				}
			}
		}, {
			path: 'login-password',
			component: () => import('@/views/profile/safe/login-password'),
			name: 'LoginPassword',
			meta: { title: 'login_password' }
		}, {
			path: 'transaction-password',
			component: () => import('@/views/profile/safe/transaction-password'),
			name: 'TransactionPassword',
			meta: { title: 'transaction_password' }
		}, {
			path: 'email',
			component: () => import('@/views/profile/safe/email'),
			name: 'Email',
			meta: { title: 'email' }
		}, {
			path: 'phone',
			component: () => import('@/views/profile/safe/phone'),
			name: 'Phone',
			meta: { title: 'phone' }
		}, {
			path: 'forget-password',
			component: () => import('@/views/profile/safe/forget-password'),
			name: 'ForgetPassword',
			meta: { title: 'forget-password' }
		}]
	}, {
		path: 'settings',
		component: () => import('@/views/profile/settings'),
		name: 'Settings',
		meta: { title: 'settings' }
	}, {
		path: 'wallet',
		children: [{
			path: '',
			component: () => import('@/views/profile/wallet'),
			name: 'Wallet',
			meta: { title: 'wallet' }
		}, {
			path: 'bill/:symbol',
			component: () => import('@/views/profile/wallet/bill'),
			name: 'WalletBill',
			meta: { title: 'wallet_bill' }
		}, {
			path: 'deposit',
			children: [{
				path: '',
				component: () => import('@/views/profile/wallet/deposit'),
				name: 'Deposit',
				meta: { title: 'deposit' }
			}, {
				path: 'bill',
				component: () => import('@/views/profile/wallet/deposit/bill'),
				name: 'DepositBill',
				meta: { title: 'deposit_bill' }
			}]
		}, {
			path: 'withdraw',
			children: [{
				path: '',
				component: () => import('@/views/profile/wallet/withdraw'),
				name: 'Withdraw',
				meta: { title: 'withdraw' }
			}, {
				path: 'bill',
				component: () => import('@/views/profile/wallet/withdraw/bill'),
				name: 'WithdrawBill',
				meta: { title: 'withdraw_bill' }
			}]
		}, {
			path: 'withdraw-error',
			component: () => import('@/views/profile/wallet/withdraw-error'),
			name: 'WithdrawError',
			meta: { title: 'withdraw_error' }
		}, {
			path: 'address-book',
			component: () => import('@/views/profile/wallet/address-book'),
			name: 'AddressBook',
			meta: { title: 'address_book' }
		}, {
			path: 'transfer',
			children: [{
				path: '',
				component: () => import('@/views/profile/wallet/transfer'),
				name: 'Transfer',
				meta: { title: 'transfer' }
			}, {
				path: 'bill',
				component: () => import('@/views/profile/wallet/transfer/bill'),
				name: 'TransferBill',
				meta: { title: 'transfer_bill' }
			}]
		}]
	}, {
		path: 'invite',
		component: () => import('@/views/profile/invite'),
		name: 'Invite',
		meta: { title: 'invite' }
	}, {
		path: 'auth',
		children: [{
			path: '',
			component: () => import('@/views/profile/auth'),
			name: 'Auth',
			meta: { title: 'auth' }
		}, {
			path: 'primary',
			component: () => import('@/views/profile/auth/primary'),
			name: 'AuthPrimary',
			meta: { title: 'auth-primary' },
		}, {
			path: 'senior',
			component: () => import('@/views/profile/auth/senior'),
			name: 'AuthSenior',
			meta: { title: 'auth-senior' },
		}]
	}]
};
