import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router';
import Layout from '@/layout';


import home from './modules/home';
import currencyExchange from './modules/currency-exchange';
import market from './modules/market';
import options from './modules/options';
import contract from './modules/contract';
import profile from './modules/profile';
import c2c from './modules/c2c';
export const routes = [
	home,
	profile,
	currencyExchange,
	market,
	options,
	contract,
	c2c, {
		path: '/customer-service',
		component: Layout,
		children: [{
			path: '',
			component: () => import('@/views/customer_service'),
			name: 'CustomerService',
			meta: { title: 'customer_service' }
		}]
	}, {
		path: '/initial-offer',
		component: Layout,
		children: [{
			path: '',
			component: () => import('@/views/initial_offer'),
			name: 'InitialOffer',
			meta: { title: 'initial_offer' }
		}]
	}, {
		path: '/login',
		component: Layout,
		children: [{
			path: '',
			component: () => import('@/views/login'),
			name: 'Login',
			meta: { title: 'login' }
		}]
	}, {
		path: '/register',
		component: Layout,
		children: [{
			path: '',
			component: () => import('@/views/register'),
			name: 'Register',
			meta: { title: 'register' }
		}]
	}, {
		path: '/academy',
		component: Layout,
		children: [{
			path: '',
			component: () => import('@/views/academy'),
			name: 'Academy',
			meta: { title: 'academy' }
		}, {
			path: 'category/:id',
			component: () => import('@/views/academy/category'),
			name: 'Category',
			meta: { title: 'category' }
		}, {
			path: 'detail/:id',
			component: () => import('@/views/academy/detail'),
			name: 'AcademyDetail',
			meta: { title: 'academy-detail' }
		}]
	}, {
		path: '/notification',
		component: Layout,
		children: [{
			path: '',
			component: () => import('@/views/notification'),
			name: 'Notification',
			meta: { title: 'notification' }
		}, {
			path: 'detail/:id',
			component: () => import('@/views/notification/detail'),
			name: 'NotificationDetail',
			meta: { title: 'notification_detail' }
		}]
	}, {
		path: '/finance',
		component: Layout,
		children: [{
			path: '',
			component: () => import('@/views/finance'),
			name: 'Finance',
			meta: { title: 'finance' }
		}, {
			path: 'subscribe/:id',
			component: () => import('@/views/finance/subscribe'),
			name: 'FinanceSubscribe',
			meta: { title: 'finance-subscribe' }
		}, {
			path: 'order',
			children: [{
				path: '',
				component: () => import('@/views/finance/order'),
				name: 'FinanceOrder',
				meta: { title: 'finance-order' },
			}/*, {
			path: 'detail/:id',
			component: () => import('@/views/finance/order/detail'),
			name: 'FinanceOrderDetail',
			meta: { title: 'finance-order-detail' },
		}*/]
		}]
	}];

// export const asyncRoutes = [];

export default createRouter({
	// mode: 'hash',
	// history: process.env.NODE_ENV === 'development' ? createWebHistory() : createWebHashHistory(),
	history: process.env.NODE_ENV !== 'dev' ? createWebHistory() : createWebHashHistory(),
	// history: createWebHashHistory(),
	routes
});
