import Layout from "@/layout";
import store from "@/store";

export default {
	path: '/market',
	component: Layout,
	children: [{
		path: '',
		component: () => import('@/views/market'),
		name: 'Market',
		meta: { title: 'market' },
		beforeEnter: async () => {
			await store.dispatch('common/getTradeSymbols');
		}
	}]
};
