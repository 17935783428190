import { createI18n } from 'vue-i18n';
import ls from "@/utils/storage";
import request from "@/utils/request";

export const supportedLocales = process.env.VUE_APP_SUPPORTED_LOCALES.split(",") || ['en'];
// const localesFiles = require.context(`./locales/${ process.env.VUE_APP_LOCALE_DIR }`, true, /\.json$/);
// export const messages = supportedLocales.reduce((locale, localeName) => {
// 	localeName = localeName.trim();
// 	locale[localeName] = localesFiles(`./${ localeName }.json`);
// 	return locale;
// }, {});

export const languagesParams = {
	"en": {
		_language: ['en'],
		_name: 'English',
		_datetimeAFormat: '{m}/{d}/{y}, {h}:{i}:{s} {A}',
		_datetimeFormat: '{m}/{d}/{y}, {h}:{i}:{s} {A}',
		_dateFormat: '{m}/{d}/{y}',
		_timeAFormat: '{h}:{i}:{s} {A}',
		_timeFormat: '{h}:{i}:{s}',
		_dateHAFormat: '{m}/{d}/{y}, {h} {A}',
		_dateHFormat: '{m}/{d}/{y}, {h}',
		_dateHIAFormat: '{m}/{d}/{y}, {h}:{i} {A}',
		_dateHIFormat: '{m}/{d}/{y}, {h}:{i}',
	},
	"ja": {
		_language: ['ja', 'ja-JP'],
		_name: '日本語'
	},
	"ko": {
		_language: ['ko', 'ko-KR'],
		_name: '한국인'
	},
	"zh-CN": {
		_language: ['zh-CN'],
		_name: '中文(简体)',
	},
	"zh-TW": {
		_language: ['zh', 'zh-CN', 'zh-HK', 'zh-MO', 'zh-SG', 'zh-TW'],
		_name: '中文(繁體)',
	},
	"de": {
		_language: ['de', 'de-AT', 'de-CH', 'de-DE', 'de-LI', 'de-LU'],
		_name: 'Deutsch',
	},
	"es": {
		_language: ['es', 'es-AR', 'es-BO', 'es-CL', 'es-CO', 'es-CR', 'es-DO', 'es-EC', 'es-ES', 'es-ES', 'es-GT', 'es-HN', 'es-MX', 'es-NI', 'es-PA', 'es-PE', 'es-PR', 'es-PY', 'es-SV', 'es-UY', 'es-VE'],
		_name: 'Español',
	},
	"fr": {
		_language: ['fr', 'fr-BE', 'fr-CA', 'fr-CH', 'fr-FR', 'fr-LU', 'fr-MC'],
		_name: 'Français',
	},
	"it": {
		_language: ['it', 'it-CH', 'it-IT'],
		_name: 'Italiano',
	},
	"uk": {
		_language: ['uk', 'uk-UA'],
		_name: 'Українська',
	},
	"tr": {
		_language: ['tr', 'tr-TR'],
		_name: 'Türk',
	}
};

export const languages = {};
// const locales = Object.keys(messages);
// locales.forEach(n => {
// 	languages[n] = languagesParams[n]._name;
// });

function defaultLocale() {
	// return process.env.VUE_APP_DEFAULT_LOCALE || locales.find(v => languagesParams[v]._language.includes(navigator.language || navigator.browserLanguage)) || 'en';
	return process.env.VUE_APP_DEFAULT_LOCALE || supportedLocales.find(v => languagesParams[v]._language.includes(navigator.language || navigator.browserLanguage)) || 'en';
	// return process.env.VUE_APP_DEFAULT_LOCALE || 'en';
}
export const languageKey = 'language';
export function getLanguage() {
	const chooseLanguage = ls.get(languageKey);
	if (chooseLanguage) return chooseLanguage;
	
	// if has not choose language
	// const language = (navigator.language || navigator.browserLanguage).toLowerCase();
	// for (const locale of locales) {
	// 	if (language.indexOf(locale) > -1) {
	// 		return locale;
	// 	}
	// }
	return defaultLocale();
}

const i18n = createI18n({
	// 如果本地有语言标识就采用本地，没有就根据浏览器语言默认标识显示语言
	locale: defaultLocale(),
	legacy: false,
	// 没有的key值不发出警告
	silentTranslationWarn: true,
	// 没有key值时应如何处理
	missing() {
		return "";
	},
	// messages
});

function loadLanguage(locale = '') {
	return new Promise((resolve, reject) => {
		try {
			if (!supportedLocales.includes(locale)) {
				reject();
			}
			
			setTimeout(() => {
				request({
					noVerification: true,
					baseURL: '/',
					url: `${ locale }.json`,
					method: 'get'
				}).then(r => {
					i18n.global.setLocaleMessage(locale, r);
					resolve();
				});
			}, 0);
		} catch (e) {
			reject(e);
		}
	});
}

/**
 * 设置语言
 * @param { string } newLocale
 */
export function setLanguage(newLocale = '') {
	return new Promise((resolve, reject) => {
		try {
			if (!supportedLocales.includes(newLocale)) {
				reject();
			}
			ls.set(languageKey, newLocale);
			
			loadLanguage(newLocale).then(_ => {
				i18n.global.locale.value = newLocale;
				document.querySelector("html").setAttribute("lang", newLocale);
				resolve();
			});
		} catch (e) {
			reject(e);
		}
	});
}
loadLanguage(defaultLocale());
const currLanguage = getLanguage();
if(currLanguage !== defaultLocale()) {
	setLanguage(currLanguage);
}
// 更新页面语言标识 让浏览器识别
document.querySelector("html").setAttribute("lang", getLanguage());

export default i18n;
