<template>
	<div v-if="isExternal(name)" :style="styleExternalIcon" class="svg-external-icon svg-icon" :class="className" v-bind="$attrs" />
	<svg v-else-if="svgIcons.includes(name)" :class="svgClass" aria-hidden="true" v-bind="$attrs" :style="setSize">
		<use :xlink:href="`#icon-${name}`" />
	</svg>
	<van-icon v-else :name="name" :class="className"/>
</template>
<script setup name="icon">
	// doc: https://panjiachen.github.io/vue-element-admin-site/feature/component/svg-icon.html#usage
	import { isExternal } from '@/utils/validate';
	import { defineProps, ref } from "vue";
	import { Icon as VanIcon } from 'vant';

	const props = defineProps({
		name: {
			type: String,
			required: true
		},
		className: {
			type: String,
			default: ''
		},
		size: {
			type: [String, Number],
			default: 16
		}
	});
	
	const size = typeof props.size === 'string' ? props.size : `${props.size}px`;
	const setSize = ref({ width: size, height: size });
	
	const req = require.context('@/icons/svg', false, /\.svg$/);
	const requireAll = requireContext => requireContext.keys();
	const reg = /\.\/(.*)\.svg/;
	const svgIcons = ref(requireAll(req).map(i => {
		return i.match(reg)[1];
	}));

	const svgClass = ref(props.className ? 'svg-icon ' + props.className : 'svg-icon');
	const styleExternalIcon = {
		mask: `url(${props.name}) no-repeat 50% 50%`,
		'-webkit-mask': `url(${props.name}) no-repeat 50% 50%`
	};
</script>

<style scoped>
.svg-icon {
	width: 1em;
	height: 1em;
	vertical-align: -0.25em;
	fill: currentColor;
	overflow: hidden;
}

.svg-external-icon {
	background-color: currentColor;
	mask-size: cover !important;
	display: inline-block;
}
</style>
