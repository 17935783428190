<template>
	<router-view/>
</template>

<script setup name="App">
	import store from "./store";
	import { allowMultipleToast } from "vant";
	allowMultipleToast();
	// 获取通用配置
	store.dispatch('common/getLogo');
	store.dispatch('common/getCountries');
	// ws链接
	store.dispatch('common/wsConnection');
</script>

<style>
#app {
	/*font-family: 'Segoe UI', 'Microsoft YaHei', sans-serif;*/
	/*font-family: PingFang SC, Avenir, Helvetica, Arial, sans-serif;*/
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
</style>
