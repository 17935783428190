import { languagesParams, getLanguage } from "@/i18n";
import useWebsocket from './socket';
import ls from './storage';
import clip from './clipboard';
import { onUnmounted, ref } from "vue";

export { useWebsocket, ls, clip };
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
	if (arguments.length === 0 || !time) {
		return null;
	}
	if (cFormat) {
		if(languagesParams[getLanguage()][`_${ cFormat }Format`]) {
			cFormat = languagesParams[getLanguage()][`_${ cFormat }Format`];
		}
		// else{
		// 	cFormat = '{y}-{m}-{d}';
		// }
	}
	const format = cFormat || languagesParams[getLanguage()]._datetimeFormat || '{y}-{m}-{d} {h}:{i}:{s}';

	let date;
	if (typeof time === 'object') {
		date = time;
	} else {
		if ((typeof time === 'string')) {
			if ((/^[0-9]+$/.test(time))) {
				// support "1548221490638"
				time = parseInt(time);
			} else {
				// support safari
				// https://stackoverflow.com/questions/4310953/invalid-date-in-safari
				time = time.replace(new RegExp(/-/gm), '/');
			}
		}

		if ((typeof time === 'number') && (time.toString().length === 10)) {
			time = time * 1000;
		}
		date = new Date(time);
	}
	const formatObj = {
		y: date.getFullYear(),
		m: date.getMonth() + 1,
		d: date.getDate(),
		h: date.getHours(),
		i: date.getMinutes(),
		s: date.getSeconds(),
		a: date.getDay(),
		get A() {
			// return this.h >= 12 ? 'PM' : 'AM';
			return date.getHours() >= 12 ? 'PM' : 'AM';
		}
	};
	if(/{A}/g.test(format)) {
		formatObj.h = formatObj.h % 12;
		formatObj.h = formatObj.h === 0 ? 12 : formatObj.h;
	}
	return format.replace(/{([ymdhisaA])+}/g, (result, key) => {
		const value = formatObj[key];
		// Note: getDay() returns 0 on Sunday
		if (key === 'a') {
			return ['日', '一', '二', '三', '四', '五', '六'][value];
		}
		return value.toString().padStart(2, '0');
	});
}

export function curryTimeTD(resolve, seconds = null) {
	return function (reject) {
		let total = seconds || 60;
		resolve(total);
		let timer = setInterval(function () {
			if (--total < 1) {
				clearInterval(timer);
				reject();
			} else {
				resolve(total);
			}

		}, 1000);
		return timer;
	};
}

export function ellipsis(str, limit = 20) {
	let realLength = 0, len = str.length, charCode = -1, intercept = '';
	for (let i = 0; i < len; i++) {
		charCode = str.charCodeAt(i);
		let currLen = 0;
		if (charCode >= 0 && charCode <= 128) {
			currLen = 1;
		} else {
			currLen = 2;
		}
		if (realLength + currLen >= limit) {
			break;
		}
		intercept += str[i];
		realLength += currLen;
	}

	return intercept + '...';
}

// export function formatNum(val, len = 2) {
// 	let temp = val.toString();
// 	temp = temp.replace(/。/g, ".");
// 	temp = temp.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
// 	temp = temp.replace(/^\./g, ""); //验证第一个字符是数字
// 	temp = temp.replace(/\.{2,}/g, ""); //只保留第一个, 清除多余的
// 	temp = temp.replace(".", "$#$")
// 		.replace(/\./g, "")
// 		.replace("$#$", ".");
// 	temp = temp.replace(new RegExp("^(\\-)*(\\d+)\\.([\\d]{" + len + "}).*$"), "$1$2.$3"); //只能输入两个小数
// 	return temp;
// }

export function nowInDateBetween(begin, end) {
	//如果时间格式是正确的，那下面这一步转化时间格式就可以不用了
	// const dateBegin = new Date(begin.replace(/-/g, "/"));//将-转化为/，使用new Date
	// const dateEnd = new Date(end.replace(/-/g, "/"));//将-转化为/，使用new Date

	const dateBegin = new Date(begin).getTime();
	const dateEnd = new Date(end).getTime();
	const dateNow = new Date().getTime();

	return dateBegin <= dateNow && dateEnd >= dateNow;
}

export function importSvg(name) {
	return import(`@/icons/${ name }`);
}

export function toNonExponential(num) {
	try {
		const m = num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);
		return num.toFixed(Math.max(0, (m[1] || '').length - m[2]));
	} catch (e) {}
	return num;
}
/**
 *
 * @param {string | number} val
 * @param {number | boolean} digits
 * @returns {string}
 */
export function formatNum(val= 0, digits = 4) {
	let temp = val = toNonExponential(+(val || 0)).toString(),
		tempDecimal = '';

	// 清除"数字"和"."以外的字符
	temp = temp.replace(/[^\d.]/g, "");
	if(val.charAt(0) === '-') {
		temp = '-' + temp;
	}
	if (temp.includes('.')) {
		temp = temp.split('.');
		tempDecimal =  temp[1];
		temp = temp[0];
	}
	let res = (temp.replace(/\B(?=(\d{3})+$)/g, ''));
	if (+tempDecimal === 0 && digits !== 0) {
		digits = 2;
	}
	if(digits === false) {
		tempDecimal = tempDecimal.replace(/(0+)$/, '');
	} else {
		if(tempDecimal.length < digits) {
			tempDecimal = tempDecimal.padEnd(digits, '0');
		} else {
			tempDecimal = tempDecimal.slice(0, digits);
		}
	}
	return res + (tempDecimal ? '.' + tempDecimal : '');
}

export function getDecimal(val) {
	return ((val || 0).toString().split('.'))[1].replace(/(0+)$/, '');
}

// export function debounce(fn, delay) {
// 	let timer = null;
// 	return function() {
// 		const context = this;
// 		const args = arguments;
// 		clearTimeout(timer);
// 		timer = setTimeout(function() {
// 			fn.apply(context, args);
// 		}, delay);
// 	};
// }

/**
 * 防抖
 * @param {Function} func
 * @param {Number} wait
 * @param {Boolean} immediate
 * @returns {(function(): void)|*}
 */
export function debounce(func, wait, immediate = true) {
	let timeout;
	return function () {
		let context = this;
		let args = arguments;

		if (timeout) clearTimeout(timeout); // timeout 不为null
		if (immediate) {
			let callNow = !timeout; // 第一次会立即执行，以后只有事件执行后才会再次触发
			timeout = setTimeout(function () {
				timeout = null;
			}, wait);
			if (callNow) {
				func.apply(context, args);
			}
		} else {
			timeout = setTimeout(function () {
				func.apply(context, args);
			}, wait);
		}
	};
}


/**
 * floatObj 包含加减乘除四个方法，能确保浮点数运算不丢失精度
 *
 * method：add / subtract / multiply /divide
 *
 * explame：
 *  0.1 + 0.2 == 0.30000000000000004
 *  1.0 - 0.9 == 0.09999999999999998
 *  19.9 * 100 == 1989.9999999999998
 *  6.6 / 0.2 == 32.99999999999999
 *
 * floatObj.add(0.1, 0.2) >> 0.3
 * floatObj.multiply(19.9, 100) >> 1990
 *
 */
export function floatObj() {
	// 判断obj是否为一个整数
	function isInteger(obj) {
		return Math.floor(obj) === obj;
	}

	/*
	 * 将一个浮点数转成整数，返回整数和倍数。如 3.14 >> 314，倍数是 100
	 * @param floatNum {number} 小数
	 * @return {object}
	 * {times:100, num: 314}
	 */
	function toInteger(floatNum) {
		floatNum = typeof floatNum === 'undefined' ? 0 : floatNum;
		floatNum = toNonExponential(+floatNum);
		const ret = { times: 1, num: 0 };
		const isNegative = floatNum < 0;
		if (isInteger(floatNum)) {
			ret.num = floatNum;
			return ret;
		}
		const strfi = floatNum + '';
		const dotPos = strfi.indexOf('.');
		const len = strfi.substr(dotPos + 1).length;
		const times = Math.pow(10, len);
		let intNum = parseInt((Math.abs(floatNum) * times + 0.5).toString(), 10);
		ret.times = times;
		if (isNegative) {
			intNum = -intNum;
		}
		ret.num = intNum;
		return ret;
	}

	/*
	 * 核心方法，实现加减乘除运算，确保不丢失精度
	 * 思路：把小数放大为整数（乘），进行算术运算，再缩小为小数（除）
	 *
	 * @param a {number} 运算数1
	 * @param b {number} 运算数2
	 * @param digits {number} 精度，保留的小数点数，比如 2, 即保留为两位小数
	 * @param op {string} 运算类型，有加减乘除（add/subtract/multiply/divide）
	 *
	 */
	function operation(a, b, digits, op) {
		const o1 = toInteger(a);
		const o2 = toInteger(b);
		const n1 = o1.num;
		const n2 = o2.num;
		const t1 = o1.times;
		const t2 = o2.times;
		const max = t1 > t2 ? t1 : t2;
		let result = null;
		switch (op) {
		case 'add'://加
			if (t1 === t2) { // 两个小数位数相同
				result = n1 + n2;
			} else if (t1 > t2) { // o1 小数位 大于 o2
				result = n1 + n2 * (t1 / t2);
			} else { // o1 小数位 小于 o2
				result = n1 * (t2 / t1) + n2;
			}
			result = result / max;
			break;
		case 'subtract'://减
			if (t1 === t2) {
				result = n1 - n2;
			} else if (t1 > t2) {
				result = n1 - n2 * (t1 / t2);
			} else {
				result = n1 * (t2 / t1) - n2;
			}
			result = result / max;
			break;
		case 'multiply'://乘
			result = (n1 * n2) / (t1 * t2);
			break;
		case 'divide'://除
			result = (n1 / n2) * (t2 / t1);
			break;
		}

		const pow = Math.pow(10, digits);
		return Math.floor(result * pow) / pow;
	}

	// 加减乘除的四个接口
	function add(a, b, digits = 2) {
		return operation(a, b, digits, 'add');
	}
	function subtract(a, b, digits = 2) {
		return operation(a, b, digits, 'subtract');
	}
	function multiply(a, b, digits = 2) {
		return operation(a, b, digits, 'multiply');
	}
	function divide(a, b, digits = 2) {
		return operation(a, b, digits, 'divide');
	}

	// exports
	return {
		add,
		subtract,
		multiply,
		divide
	};
}
export function useDefer() {
	const count = ref(0);
	let raqId = null;
	function updateFrame() {
		count.value++;
		raqId = requestAnimationFrame(updateFrame);
	}
	updateFrame();
	onUnmounted(() => {
		cancelAnimationFrame(raqId);
	});
	return function (n) {
		return count.value >= n;
	};
}

export function showDefault(v, d) {
	if(+v) {
		return formatNum(v, d);
	}
	return '--';
}

export function isEmptyObject(obj) {
	return !Reflect.ownKeys(obj).length;
}

export function isDuringDate(beginDateStr, endDateStr) {
	const curDate = new Date(),
		beginDate = new Date(beginDateStr),
		endDate = new Date(endDateStr);

	return curDate >= beginDate && curDate <= endDate;
}

export function usernameToStar(username, usernameType = 1, starLen = 4) {
	if(usernameType === 1) {
		return username.replace(/(\d{1})[\d]+(\d{1})$/, '$1****$2');
	}
	if(usernameType === 2) {
		const arr = username.split('@');
		return username.substring(0, 3) + '*'.padEnd(starLen, '*') + '@' + arr[1];
	}
}

export function offsetToHoursMinutes(offset) {
	// 东8区
	let UTCType = '+';
	if(offset < 0) {
		// 西8区
		UTCType = '-';
	}
	offset = Math.abs(offset);
	// 将偏移量转换为小时和分钟
	// 将小时和分钟格式化为两位数字
	const hours = (Math.floor(offset / 60)).toString().padStart(2, '0');
	const minutes = (offset % 60).toString().padStart(2, '0');
	
	// 返回时:分格式的字符串
	return `${UTCType}${hours}:${minutes}`;
}

export function getOffset(timeZone = 'UTC', date = new Date()) {
	const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
	const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
	return offsetToHoursMinutes((tzDate.getTime() - utcDate.getTime()) / 6e4);
}

/**
 * 生成连续多次函数
 * @param {function} cb
 * @param {number} num
 * @param {number} duration
 * @returns {function}
 */
export function genCombo(cb, num = 2, duration = 0) {
	const queue = [];
	duration = duration || num * 200;
	const fn = () => {
		const timestamp = new Date().getTime();
		if(queue.length === num - 1){
			//判断用户在每次点击的时候 是否够次数
			let s = timestamp - queue[0];
			//如果够7次就判断一下 这次点击的时间戳跟第一次点击的事件戳间隔是否相差5s
			if(s <= duration){
				cb();
				//是的话 就 清空队列，做操作
				queue.length = 0;
			}else {
				//不是的话 就进队同时出队，保持队列最大长度为6
				queue.push(timestamp);
				queue.shift();
			}
		}else{
			// 反之只做入队操作即可
			queue.push(timestamp);
		}
	};
	return fn;
}
