import request from '@/utils/request';

export function getMarketList() {
	return request({
		url: 'exchange/getMarketList',
		method: 'get',
	});
}

export function getMarketInfo(params) {
	return request({
		url: 'exchange/getMarketInfo',
		method: 'get',
		params
	});
}

export function getCurrentEntrust(params) {
	return request({
		url: 'exchange/getCurrentEntrust',
		method: 'get',
		params
	});
}

export function cancelEntrust(data) {
	return request({
		url: 'exchange/cancelEntrust',
		method: 'post',
		data
	});
}

export function getHistoryEntrust(params) {
	return request({
		url: 'exchange/getHistoryEntrust',
		method: 'get',
		params
	});
}

export function getUserCoinBalance(params) {
	return request({
		url: 'exchange/getUserCoinBalance',
		method: 'get',
		params
	});
}

export function storeEntrust(data) {
	return request({
		url: 'exchange/storeEntrust',
		method: 'post',
		data
	});
}
