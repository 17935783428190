import { createApp } from 'vue';

import './icons'; // icon
import 'bootstrap/dist/css/bootstrap.min.css';
import 'vant/lib/index.css';
import '@/assets/scss/index.scss';


import router from '@/router';
import i18n from '@/i18n';
import store from '@/store';
import components from '@/components';
import App from './App';
import '@/permission';

createApp(App)
	.use(i18n)
	.use(router)
	.use(components)
	.use(store)
	.mount('#app');
