import axios from 'axios';
// import { ElMessage, ElMessageBox } from 'element-plus';
import { showToast } from 'vant';

import store from '@/store';
import { getToken, tokenExpired } from '@/utils/auth';
import { getLanguage } from "@/i18n";

// create an axios instance
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const service = axios.create({
	baseURL: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_REQUEST_BASE_URL : process.env.VUE_APP_BASE_URL, // url = base url + request url
	withCredentials: process.env.NODE_ENV === 'development', // send cookies when cross-domain requests
	timeout: 20000, // request timeout,
	timezone
});

const CancelToken = axios.CancelToken;
let cancelMap = new Map();

// request interceptor
service.interceptors.request.use(
	config => {
		if(config?.cancel !== false) {
			if(cancelMap.has(config.url)) {
				cancelMap.get(config.url)();
				cancelMap.delete(config.url);
			}
			config.cancelToken = new CancelToken(function (c) {
				cancelMap.set(config.url, c);
			});
		}

		config.headers['lang'] = getLanguage();
		// do something before request is sent

		if (store.getters.token) {
			// let each request carry token
			// ['X-Token'] is a custom headers key
			// please modify it according to the actual situation
			config.headers['Authorization'] = 'Bearer' + getToken();
		}
		return config;
	},
	error => {
		// do something with request error
		console.log(error); // for debug
		return Promise.reject(error);
	}
);

// response interceptor
service.interceptors.response.use(
	/**
	 * If you want to get http information such as headers or status
	 * Please return  response => response
	 */

	/**
	 * Determine the request status by custom code
	 * Here is just an example
	 * You can also judge the status by HTTP Status Code
	 */
	response => {
		const res = response.data;
		if(response.config.noVerification) {
			return res;
		}
		// 无痛刷新token
		const token = response.headers.Authorization;
		if (token) {
			// 如果 header 中存在 token，那么触发 refreshToken 方法，替换本地的 token
			store.dispatch('auth/refreshToken', token).then(() => {

			});
		}

		// if the custom code is not 20000, it is judged as an error.
		// if (res.code !== 0 && res.code !== 200) {
		if (res.code !== 200) {
			// ElMessage({
			// 	message: res.message || 'Error',
			// 	type: 'error',
			// 	duration: 5 * 1000
			// });
			showToast({
				message: res.message || 'Error',
				onClose() {
					if (res.code === 1003) {
						store.dispatch('auth/resetToken').then(() => {
							tokenExpired();
							location.reload();
						});
					}
				}
			});

			// 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
			// if (res.code === 1401 || res.code === 50008 || res.code === 50012 || res.code === 50014) {

			// if (res.code === 1003) {
			// 	showFailToast({
			// 		message: res.message || 'Error',
			// 		onClose() {
			// 			store.dispatch('auth/resetToken').then(() => {
			// 				tokenExpired();
			// 				location.reload();
			// 			});
			// 		}
			// 	});
			// }
			// return Promise.reject(new Error(res.message || 'Error'));
			return Promise.reject(res);
		} else {
			return res;
		}
	},
	error => {
		console.log(error);
		if(error.code === 'ECONNABORTED') {
			// service(error.config);
			showToast('timeout');
			return;
		}
		if(error.code === "ERR_NETWORK") {
			showToast(error.message);
			return;
		}
		if(error.code === "ERR_CANCELED") {
			// showToast(error.message);
			return;
		}

		// console.log(error); // for debug
		let res = error.response.data;
		if (typeof res.errors === 'object') {
			for (let n in res.errors) {
				for (let x of res.errors[n]) {
					setTimeout(function() {
						showToast(x);
						// ElMessage({
						// 	message: x,
						// 	type: 'error',
						// 	duration: 5 * 1000
						// });
					}, 100);
				}
			}
		} else {
			showToast(res.message);
			// ElMessage({
			// 	message: res.message,
			// 	type: 'error',
			// 	duration: 5 * 1000
			// });
		}
		return Promise.reject(error);
	}
);

export default service;
