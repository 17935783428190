import request from '@/utils/request';

export function getMarketInfo(params) {
	return request({
		url: 'contract/getMarketInfo',
		method: 'get',
		params
	});
}

export function getMarketList(params) {
	return request({
		url: 'contract/getMarketList',
		method: 'get',
		params
	});
}

export function contractAccount(params) {
	return request({
		url: 'contract/contractAccount',
		method: 'get',
		params
	});
}

export function holdPosition(params) {
	return request({
		url: 'contract/holdPosition',
		method: 'get',
		params
	});
}

export function getOpenNum(params) {
	return request({
		url: 'contract/openNum',
		method: 'get',
		params
	});
}

export function getSymbolDetail(params) {
	return request({
		url: 'contract/getSymbolDetail',
		method: 'get',
		params
	});
}

export function getCurrentEntrust(params) {
	return request({
		url: 'contract/getCurrentEntrust',
		method: 'get',
		params
	});
}

export function cancelEntrust(data) {
	return request({
		url: 'contract/cancelEntrust',
		method: 'post',
		data
	});
}

export function getHistoryEntrust(params) {
	return request({
		url: 'contract/getHistoryEntrust',
		method: 'get',
		params
	});
}

export function onekeyAllFlat(data) {
	return request({
		url: 'contract/onekeyAllFlat',
		method: 'post',
		data
	});
}

export function onekeyReverse(data) {
	return request({
		url: 'contract/onekeyReverse',
		method: 'post',
		data
	});
}

export function closePosition(data) {
	return request({
		url: 'contract/closePosition',
		method: 'post',
		data
	});
}

export function setStrategy(data) {
	return request({
		url: 'contract/setStrategy',
		method: 'post',
		data
	});
}

export function openPosition(data) {
	return request({
		url: 'contract/openPosition',
		method: 'post',
		data
	});
}

export function positionShare(params) {
	return request({
		url: 'contract/positionShare',
		method: 'get',
		params
	});
}
