import { useClipboard } from '@vueuse/core';
import { showSuccessToast, showFailToast } from 'vant';
const { isSupported, copy } = useClipboard();
import i18n  from "@/i18n";
//
const { t } = i18n.global;

const clip = async (text) => {
	if (isSupported) {
		await copy(text);
		showSuccessToast(t('copy_success'));
	} else {
		showFailToast(t('copy_error'));
	}
};

export default clip;
