import router from "@/router";
import nprogress from 'nprogress';
import store from "@/store";
// import { showFailToast } from 'vant';
import 'nprogress/nprogress.css';
import { isTokenExpired, removeTokenExpired } from '@/utils/auth';
import { showToast } from "vant";

nprogress.configure({ showSpinner: false });

const whiteList = ['/login', '/register', '/profile/safe/forget-password', '/academy/detail/91', '/academy/detail/93'];

// 发现使用next跳转会慢很多
// return true 或者什么都不处理就会自动跳转到目标路由
// router.beforeEach(async (to, from, next) => {
router.beforeEach(async (to) => {
	nprogress.start();
	
	// token 是否过期
	if(isTokenExpired()) {
		console.log(to);
		removeTokenExpired();
		return `/login?redirect=${ encodeURIComponent(to.fullPath.replace('?', '&')) }`;
	}
	
	// get my info
	if (store.getters.token && !(Object.keys(store.getters.userInfo).length)) {
		try {
			await store.dispatch('auth/getInfo');
		} catch (e) {
			console.log(e);
			showToast(e);
			store.dispatch('auth/resetToken');
		}
	}
	
	if(store.getters.hasLogin) {
		if(whiteList.includes(to.path)) {
			return '/';
		}
	} else {
		if(!whiteList.includes(to.path)) {
			// next(`/login?redirect=${ encodeURIComponent(to.fullPath.replace('?', '&')) }`);
			return `/login?redirect=${ encodeURIComponent(to.fullPath.replace('?', '&')) }`;
		}
	}
});

router.afterEach(() => {
	// finish progress bar
	nprogress.done();
});
